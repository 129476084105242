import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/voyage'

class VoyageService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    indexNames() {
        return http.get(`${this.getUrl()}?names_only=1`)
    }
    findVoyage(vesselId, number) {
        return http.post(`${this.getUrl()}/find`, {"vessel_id": vesselId, "number": number})
    }
    searchVoyages(portData) {
        return http.post(`${this.getUrl()}/search`, portData)
    }
    searchVoyagesTransshipment(portData) {
        return http.post(`${this.getUrl()}/search/transshipment`, portData)
    }
    getInvoiceReadyVoyages(){
        return http.get(`${this.getUrl()}/billable-bls`);
    }
    getShiploadInvoiceVoyages(option){
        return http.get(`/api/legacy/shipload/bl/${option}`);
    }
    updateRepresentativesCount(id, count){
        return http.post(`/api/legacy/shipload/representatives-count/update`, {'id': id, 'count': count});
    }
    getInvoiceReadyVoyage(id, customerId, blIds){
        const substr = `?customer_id=${customerId}&bl_ids=${blIds}`;
        return http.get(`${this.getUrl()}/${id}/billable-bls${substr}`);
    }
    getInvoiceReadyVoyageShipload(id, blIds){
        return http.post(`/api/legacy/shipload/bl/${id}/invoice-data`, {checked_shipload_ids: blIds});
    }
    getPortsAndOperators(id, getCustomersToo = false){
        const qstring = getCustomersToo ? '?getcustomers=1' : '';
        return http.get(`${this.getUrl()}/${id}/ports-and-operators` + qstring);
    }
    downloadExcelExport(data){
        return http.post(`${URL}/generate-export`, data, {'responseType': 'arraybuffer'})
    }
    showManifest(id){
        return http.get(`${URL}/manifest/${id}`)
    }
    showManifestPost(id, pols, pods, bls, transit_bls, downloadType, format, manifest, extraOptions){
        const options = {'pols': pols, 'pods': pods, 'bls': bls, 'transit_bls': transit_bls, 'download_type': downloadType, 'format': format, 'manifest_type': manifest, 'extra_options': extraOptions};
        if(format === 'EXCEL' || format === 'PDF' || extraOptions.zipped == 2){
            return http.post(`${URL}/generate-manifest/${id}`, options, {'responseType': 'arraybuffer'})
        }
        return http.post(`${URL}/generate-manifest/${id}`, options)
    }
    saveChecklistOption(options){
        return http.post(`api/manifest/toggle`, options)
    }
    showAllBLs(bls, zipped, extraOptions){
        return http.post(`${URL}/generate-bls`, {'bls': bls, 'zipped': zipped, 'extra_options': extraOptions}, {'responseType': 'arraybuffer'})
    }
}

export default new VoyageService()